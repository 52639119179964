const getExtensionData = async (paginatedValue) => {
  const baseUrl = window.location.hostname.includes("localhost")
    ? "https://staging.zopping.com"
    : window.location.origin;
  try {
    const bearer = "Bearer " + window.localStorage.getItem("token");
    const getExtensionRuleData = await fetch(
      `${baseUrl}/api/account-service/extension?paginated=${paginatedValue}`,
      { headers: { Authorization: bearer } }
    );
    const getExtensionRuleJson = await getExtensionRuleData.json();
    return getExtensionRuleJson;
  } catch (err) {
    console.error(err);
  }
};

export default getExtensionData;
