const enableDisableExtension = (id, status) => {
  const baseUrl = window.location.hostname.includes("localhost")
    ? "https://staging.zopping.com"
    : window.location.origin;

  const formData = new FormData();
  formData.append("paginated", "false");
  formData.append("status", status);
  const bearer = "Bearer " + window.localStorage.getItem("token");

  return window.fetch(`${baseUrl}/api/account-service/extension/${id}`, {
    method: "PUT",
    body: formData,
    headers: { Authorization: bearer },
  });
};

export default enableDisableExtension;
