import animateScrollTo from "animated-scroll-to";
import React, { Component } from "react";
import Helmet from "react-helmet";
import WithProviders from "../../components/WithProviders";
import getExtensionData from "../../lib/getExtensionData";
import { Message } from "../../lib/getMessages";
// import { clearSession } from "../../lib/session";
import EazyHeader from "../../components/Header";
import LayoutWrapper from "../../components/Layout";
import SlideShow from "../../components/SlideShow";
import AdminApp from "../../images/Admin-App.svg";
import Analytics from "../../images/Analytics.svg";
import AnalyticsIcon from "../../images/Analytics.webp";
import AppsIcon from "../../images/Apps.webp";
import CampaignManagement from "../../images/Campaign-Management.svg";
import Blogs from "../../images/Blogs.svg";
import ReviewRatings from "../../images/Review-Rating.svg";
import RefferalManagement from "../../images/Refferal-Management.svg";
import GoogleMerchantCenter from "../../images/Google-Merchant-Center.svg";
import CashOnDelivery from "../../images/Cash-on-Delivery.svg";
import CatalogueIcon from "../../images/Catalogue.webp";
import ChannelsIcon from "../../images/Channels.webp";
import CouponsIcon from "../../images/Coupons.svg";
import CustomerApp from "../../images/Customer-App.svg";
import CustomerDetails from "../../images/Customer-details.svg";
import CustomerPassword from "../../images/Customer-password-format.svg";
import CustomerManagement from "../../images/CustomerManagement.webp";
import DataSecurity from "../../images/Data-Security.svg";
import DataSecurityIcon from "../../images/DataSecurity.webp";
import DeliveryApp from "../../images/Delivery-App.svg";
import DeliveryAreaManagement from "../../images/Delivery-Area-Management.svg";
import DeliveryPartnerIntegration from "../../images/Delivery-Partner-Integration.svg";
import DeliveryIcon from "../../images/Delivery.webp";
import FacebookAnalyticsIcon from "../../images/Facebook-Analytics.svg";
import FeaturesBanner1 from "../../images/FeaturesBanner1.svg";
import FeaturesBanner2 from "../../images/FeaturesBanner2.svg";
import FeaturesBanner3 from "../../images/FeaturesBanner3.svg";
import GiftCards from "../../images/Gift-Cards.svg";
import GoogleAnalyticsIcon from "../../images/Google-Analytics.svg";
import Hosting from "../../images/Hosting.svg";
import ItemsSoldWeight from "../../images/Items-Sold-by-Weight.svg";
import LiveChat from "../../images/Live-chat.svg";
import MarketingIcon from "../../images/Marketing.webp";
import MultiLanguage from "../../images/Multi-language.svg";
import MultiStore from "../../images/Multi-store.svg";
import MultiUserAccess from "../../images/Multi-user-access.svg";
import OfferManagement from "../../images/Offers-Management.svg";
import OnlineStoreIcon from "../../images/OnlineStore.webp";
import OrderAlerts from "../../images/Order-Alerts.svg";
import OrderReturns from "../../images/Order-Returns.svg";
import OrderManageIcon from "../../images/OrderManagement.webp";
import OrdersDashboard from "../../images/Orders-Dashboard.svg";
import PaymentGateway from "../../images/Payment-Gateway.svg";
import PaymentsIcon from "../../images/Payments.webp";
import PayPalIntegration from "../../images/PayPal-Integration.svg";
import PickerApp from "../../images/Picker-App.svg";
import ProductListing from "../../images/Product-Listing.svg";
import ProductSubstitution from "../../images/product-substitution.svg";
import ProductVariant from "../../images/Product-Variants.svg";
import ReportsIcon from "../../images/Reports.svg";
import SearchBox from "../../images/Search-Box.svg";
import SeoIcon from "../../images/Search-Engine-Optimization.svg";
import SellbyBrand from "../../images/Sell-by-Brand.svg";
import SslCertificate from "../../images/SSL-Certificate.svg";
import StaffManagement from "../../images/StaffManagement.webp";
import StockManagement from "../../images/Stock-management.svg";
import ThemesIcon from "../../images/Themes.svg";
import TickCheckbox from "../../images/tick-icon-white.svg";
import WebpagesBanners from "../../images/Webpages-Banners.svg";
import EWallet from "../../images/Your-eWallet.svg";
import enableDisableExtension from "../../utils/enableDisableExtension";
import "./features.css";

const FeaturesAndDetails = [
  {
    onlineStore: [
      {
        hosting: {
          desc: "hosting.desc",
          extensionRequired: false,
          icon: Hosting,
        },
      },
      {
        ssl: {
          desc: "ssl.desc",
          extensionRequired: false,
          icon: SslCertificate,
        },
      },
      {
        themes: {
          desc: "themes.desc",
          extensionRequired: false,
          icon: ThemesIcon,
        },
      },
      {
        "search.box": {
          desc: "search.box.desc",
          extensionRequired: false,
          icon: SearchBox,
        },
      },
      {
        "multi.language": {
          desc: "multi.language.desc",
          extensionRequired: false,
          icon: MultiLanguage,
        },
      },
    ],
  },
  {
    cataloguing: [
      {
        "product.listing": {
          desc: "product.listing.desc",
          extensionRequired: false,
          icon: ProductListing,
        },
      },
      {
        "sell.by.brand": {
          desc: "sell.by.brand.desc",
          extensionRequired: false,
          icon: SellbyBrand,
        },
      },
      {
        "items.sold.by-weight": {
          desc: "items.sold.by-weight.desc",
          extensionRequired: false,
          icon: ItemsSoldWeight,
        },
      },
      {
        "product.variants": {
          desc: "product.variants.desc",
          extensionRequired: false,
          icon: ProductVariant,
        },
      },
      {
        "product.subsitution": {
          desc: "product.subsitution.desc",
          extensionRequired: false,
          icon: ProductSubstitution,
        },
      },
      {
        stockManagement: {
          desc: "stock.management.desc",
          extensionRequired: false,
          icon: StockManagement,
        },
      },
    ],
  },
  {
    payments: [
      {
        "payment.gateway.3rd.party": {
          desc: "payment.gateway.3rd.party.desc",
          extensionRequired: false,
          icon: PaymentGateway,
        },
      },
      {
        "paypal.integration": {
          desc: "paypal.integration.desc",
          extensionRequired: false,
          icon: PayPalIntegration,
        },
      },
      {
        "your.ewallet": {
          desc: "your.ewallet.desc",
          extensionRequired: false,
          icon: EWallet,
        },
      },
      {
        giftCards: {
          desc: "gift.cards.desc",
          extensionRequired: false,
          icon: GiftCards,
        },
      },
      {
        cod: {
          desc: "cod.desc",
          extensionRequired: false,
          icon: CashOnDelivery,
        },
      },
    ],
  },
  {
    marketing: [
      {
        "webpages.and.banners": {
          desc: "webpages.and.banners.desc",
          extensionRequired: false,
          icon: WebpagesBanners,
        },
      },
      {
        "offers.management": {
          desc: "offers.management.desc",
          extensionRequired: false,
          icon: OfferManagement,
        },
      },
      {
        "campaign.management": {
          desc: "campaign.management.desc",
          extensionRequired: false,
          icon: CampaignManagement,
        },
      },
      {
        coupons: {
          desc: "coupons.desc",
          extensionRequired: false,
          icon: CouponsIcon,
        },
      },
      {
        seo: {
          desc: "seo.desc",
          extensionRequired: false,
          icon: SeoIcon,
        },
      },
      {
        "referral.management": {
          desc: "referral.management.desc",
          extensionRequired: false,
          icon: RefferalManagement,
        },
      },
      {
        "google.merchant.center": {
          desc: "google.merchant.center.desc",
          extensionRequired: false,
          icon: GoogleMerchantCenter,
        },
      },
      {
        "reviews.ratings": {
          desc: "reviews.ratings.desc",
          extensionRequired: false,
          icon: ReviewRatings,
        },
      },
      {
        blogs: {
          desc: "blogs.desc",
          extensionRequired: false,
          icon: Blogs,
        },
      },
    ],
  },
  {
    orderManagement: [
      {
        "orders.dashboard": {
          desc: "orders.dashboard.desc",
          extensionRequired: false,
          icon: OrdersDashboard,
        },
      },
      {
        "orders.alerts": {
          desc: "orders.alerts.desc",
          extensionRequired: false,
          icon: OrderAlerts,
        },
      },
    ],
  },
  {
    delivery: [
      {
        "delivery.area.management": {
          desc: "delivery.area.management.desc",
          extensionRequired: false,
          icon: DeliveryAreaManagement,
        },
      },
      {
        "delivery.partner.integration": {
          desc: "delivery.partner.integration.desc",
          extensionRequired: false,
          icon: DeliveryPartnerIntegration,
        },
      },
    ],
  },
  {
    "customer.management": [
      {
        "customer.details": {
          desc: "customer.details.desc",
          extensionRequired: false,
          icon: CustomerDetails,
        },
      },
      {
        "live.chat": {
          desc: "live.chat.desc",
          extensionRequired: false,
          icon: LiveChat,
        },
      },
      {
        "order.returns": {
          desc: "order.returns.desc",
          extensionRequired: false,
          icon: OrderReturns,
        },
      },
    ],
  },
  {
    staffManagement: [
      {
        "multi.user.access": {
          desc: "multi.user.access.desc",
          extensionRequired: false,
          icon: MultiUserAccess,
        },
      },
    ],
  },
  {
    channels: [
      {
        "multi.store": {
          desc: "multi.store.desc",
          extensionRequired: false,
          icon: MultiStore,
        },
      },
    ],
  },
  {
    analytics: [
      {
        reports: {
          desc: "reports.desc",
          extensionRequired: false,
          icon: ReportsIcon,
        },
      },
      {
        analytics: {
          desc: "analytics.desc",
          extensionRequired: false,
          icon: Analytics,
        },
      },
      {
        "fb.analytics": {
          desc: "fb.analytics.desc",
          extensionRequired: false,
          icon: FacebookAnalyticsIcon,
        },
      },
      {
        "google.analytics": {
          desc: "google.analytics.desc",
          extensionRequired: false,
          icon: GoogleAnalyticsIcon,
        },
      },
    ],
  },
  {
    apps: [
      {
        "customer.app": {
          desc: "customer.app.desc",
          extensionRequired: false,
          icon: CustomerApp,
        },
      },
      {
        "delivery.app": {
          desc: "delivery.app.desc",
          extensionRequired: false,
          icon: DeliveryApp,
        },
      },
      {
        "picker.app": {
          desc: "picker.app.desc",
          extensionRequired: false,
          icon: PickerApp,
        },
      },
      {
        "admin.app": {
          desc: "admin.app.desc",
          extensionRequired: false,
          icon: AdminApp,
        },
      },
    ],
  },
  {
    "data.and.security": [
      {
        "custom.password.format": {
          desc: "custom.password.format.desc",
          extensionRequired: false,
          icon: CustomerPassword,
        },
      },
      {
        "data.security": {
          desc: "data.security.desc",
          extensionRequired: false,
          icon: DataSecurity,
        },
      },
    ],
  },
];

const bannerFeaturesList = [
  {
    id: 1,
    image: FeaturesBanner1,
    desc: "features.banner.one.description",
  },
  {
    id: 2,
    image: FeaturesBanner2,
    desc: "features.banner.two.description",
  },
  {
    id: 3,
    image: FeaturesBanner3,
    desc: "features.banner.three.description",
  },
];
class RadioButton extends Component {
  render() {
    const { index, checkBoxStatus, changeCheckBoxStatus, extensionDetails } =
      this.props;
    return (
      <label className="feature-select-label">
        <input
          className="features-select-checkbox"
          name="feature"
          type="checkbox"
          checked={checkBoxStatus}
          onChange={(e) => changeCheckBoxStatus(e, index)}
          disabled={!Array.isArray(extensionDetails)}
        />
        <span className="feature-checkbox-span">
          <img className="feature-checkbox-tick" alt="" src={TickCheckbox} />
        </span>
      </label>
    );
  }
}

class FeatureSubcategories extends Component {
  render() {
    const {
      isLoggedIn,
      details,
      checkBoxStatus,
      index,
      changeCheckBoxStatus,
      category,
      isDirectionRTL,
      // isFeatureAlreadyInstalled
    } = this.props;
    if (isLoggedIn && !details.extensionRequired) return null;
    return (
      <div className="feature-subcategory">
        {isLoggedIn && (
          <RadioButton
            desc={details.desc}
            extensionDetails={details.extensionRequired}
            checkBoxStatus={checkBoxStatus?.[index] || false}
            changeCheckBoxStatus={changeCheckBoxStatus}
            index={index}
          />
        )}
        <div className="feature-title-desc-wrp">
          <div className="feature-title-icon-wrp">
            <img src={details.icon} alt="feature-icon" />
            <p
              className={`feature-category-title ${
                isDirectionRTL ? "rtl-support" : ""
              }`}
            >
              <Message dictKey={category} />
            </p>
          </div>
          <p
            className={`feature-category-description ${
              isDirectionRTL ? "rtl-support" : ""
            }`}
          >
            <Message dictKey={details.desc} />
          </p>
        </div>
        {/* {isFeatureAlreadyInstalled?.[index] && (
            <span className="installed-label"><Message dictKey="installed" /></span>
          )} */}
      </div>
    );
  }
}

class FeaturesList extends Component {
  scrollCurrentFeature = (id) => (e) => {
    e.preventDefault();
    let featureDiv = document.querySelector(id);
    const options = {
      // duration of the scroll per 1000px, default 500
      speed: 500,

      // minimum duration of the scroll
      minDuration: 250,

      // maximum duration of the scroll
      maxDuration: 1500,

      // DOM element to scroll, default window
      // Pass a reference to a DOM object
      // Example: document.querySelector('#element-to-scroll'),
      element: window,

      // should animated scroll be canceled on user scroll/keypress
      // if set to "false" user input will be disabled until animated scroll is complete
      cancelOnUserAction: true,

      // Set passive event Listeners to be true by default. Stops Chrome from complaining.
      passive: true,

      // Scroll horizontally rather than vertically (which is the default)
      horizontal: false,

      // function that will be executed when the scroll animation is finished
      onComplete: function () {},
    };

    const desiredOffset = parseInt(featureDiv.offsetTop) - 100;
    animateScrollTo(desiredOffset, options);
  };

  render() {
    let activeClass = "";
    if (this.props.currentNav === this.props.id) {
      activeClass += "feature-active";
    }
    return (
      <li
        className={`${activeClass} ${
          this.props.isDirectionRTL ? "rtl-support" : ""
        }`}
      >
        <a
          href={"#" + this.props.id}
          className="feature-links"
          id={"#" + this.props.id}
          onClick={this.scrollCurrentFeature(`#${this.props.id}`)}
        >
          <Message dictKey={this.props.feature} />
        </a>
      </li>
    );
  }
}

class FeatureCategories extends Component {
  constructor(props) {
    super(props);
    this.getFeatureSubCategories = this.getFeatureSubCategories.bind(this);
    this.state = {
      checkBoxStatus: null, // will hold an array for all sub-features checkbox state
      submitting: false, // submit state for both feature install and uninstall btn
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.allExtensionStatus !== this.props.allExtensionStatus) {
      const initialCheckBoxStatus = nextProps.details.map((category, index) => {
        let featureInstalled =
          nextProps.details[index][Object.keys(category)].extensionRequired ===
          "Not_KNOWN"
            ? false
            : true;
        nextProps.allExtensionStatus &&
          Array.isArray(
            nextProps.details[index][Object.keys(category)].extensionRequired
          ) &&
          nextProps.details[index][
            Object.keys(category)
          ].extensionRequired.forEach((extension) => {
            if (nextProps.allExtensionStatus[extension].status === "DISABLED") {
              featureInstalled = false;
            }
          });
        return featureInstalled;
      });
      this.isFeatureAlreadyInstalled = initialCheckBoxStatus;
      let enableUninstallBtn = true;
      initialCheckBoxStatus.forEach((status) => {
        if (!status) {
          enableUninstallBtn = false;
        }
      });
      this.setState({
        checkBoxStatus: initialCheckBoxStatus,
        isFeatureAlreadyInstalled: initialCheckBoxStatus,
        enableUninstallBtn,
      });
    }
  }

  changeCheckBoxStatus = (e, index) => {
    const { checked } = e?.target;
    this.setState((prevState) => {
      const newCheckBoxStatus = [...prevState.checkBoxStatus];
      newCheckBoxStatus[index] = checked;
      return {
        checkBoxStatus: newCheckBoxStatus,
      };
    });
  };
  getFeatureSubCategories() {
    let fsc = this.props.details.map((category, index) => {
      return (
        <FeatureSubcategories
          key={"fsc-" + index}
          category={Object.keys(category)}
          details={this.props.details[index][Object.keys(category)]}
          isLoggedIn={this.props.isLoggedIn}
          allExtensionStatus={this.props.allExtensionStatus}
          checkBoxStatus={this.state.checkBoxStatus}
          index={index}
          changeCheckBoxStatus={this.changeCheckBoxStatus}
          isFeatureAlreadyInstalled={this.isFeatureAlreadyInstalled}
          isDirectionRTL={this?.props?.isDirectionRTL}
        />
      );
    });

    return fsc;
  }

  /* 
    to install new sub-features which user selects through checkbox, 
    and uninstall those which user unselects
  */
  installSelectedFeatures = () => {
    this.setState({ submitting: true });
    const extensionRequiredSubFeature = this.props.details // all sub-features details
      .map((detail) =>
        Object.values(detail) // set of extensions required for a sub-feature (in an array)
          .filter(({ extensionRequired }) => Array.isArray(extensionRequired))
          .map(({ extensionRequired }) => extensionRequired)
      );

    // get all the new extensions to be installed selected by the user
    const allExtensionsToInstall = [];
    extensionRequiredSubFeature
      .filter(
        (_, index) =>
          !this.isFeatureAlreadyInstalled[index] &&
          this.state.checkBoxStatus[index]
      ) // filter new sub-features to be installed
      .filter((featureExtensionArray) => featureExtensionArray.length)
      .forEach((featureExtensionArray) =>
        featureExtensionArray.forEach((extensionArray) =>
          extensionArray.forEach((extension) =>
            allExtensionsToInstall.push(extension)
          )
        )
      );
    const allUniqueExtensionsToInstall = [...new Set(allExtensionsToInstall)];
    const allUniqueExtensionsIdToInstall = allUniqueExtensionsToInstall.map(
      (extension) => this.props.allExtensionStatus[extension].id
    );

    // get all the extensions to be uninstalled selected by the user
    const allExtensionsToUninstall = [];
    extensionRequiredSubFeature
      .filter(
        (_, index) =>
          this.isFeatureAlreadyInstalled[index] &&
          !this.state.checkBoxStatus[index]
      ) // filter sub-features to be uninstalled
      .filter((featureExtensionArray) => featureExtensionArray.length)
      .forEach((featureExtensionArray) =>
        featureExtensionArray.forEach((extensionArray) =>
          extensionArray.forEach((extension) =>
            allExtensionsToUninstall.push(extension)
          )
        )
      );
    const allUniqueExtensionsToUninstall = [
      ...new Set(allExtensionsToUninstall),
    ];
    const allUniqueExtensionsIdToUninstall = allUniqueExtensionsToUninstall.map(
      (extension) => this.props.allExtensionStatus[extension].id
    );

    // to call api for installing and uninstalling the selected extensions
    Promise.all([
      Promise.all(
        allUniqueExtensionsIdToInstall.map((id) =>
          enableDisableExtension(id, "ENABLED")
        )
      ), // installing(enable) extensions
      Promise.all(
        allUniqueExtensionsIdToUninstall.map((id) =>
          enableDisableExtension(id, "DISABLED")
        )
      ), // uninstalling(disable) extensions
    ])
      .then((response) => {
        this.props.updateAllExtensionStatus(() => {
          this.setState({ submitting: false });
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submitting: false });
      });
  };

  /* 
    can only be called when all the sub-features in a feature are enabled.
    user can uncheck the sub-features to not uninstall, all the checked sub-features will be uninstalled.
  */
  uninstallSelectedFeatures = () => {
    this.setState({ submitting: true });
    const allExtensions = [];
    this.props.details // all sub-features details
      .map((detail) =>
        Object.values(detail) // set of extensions required to uninstall a sub-feature (in an array)
          .filter(({ extensionRequired }) => Array.isArray(extensionRequired))
          .map(({ extensionRequired }) => extensionRequired)
      )
      .filter((_, index) => this.state.checkBoxStatus[index]) // filter the sub-features which the user has selected to be uninstalled through checkbox
      .filter((featureExtensionArray) => featureExtensionArray.length) // filter sub-features for which at least 1 extension is required
      .forEach((featureExtensionArray) =>
        featureExtensionArray.forEach(
          (
            extensionArray // to create allExtensions array which holds all the extensions required to uninstall the selected features
          ) =>
            extensionArray.forEach((extension) => allExtensions.push(extension))
        )
      );
    const allUniqueExtensions = [...new Set(allExtensions)];
    const allUniqueExtensionsId = allUniqueExtensions.map(
      (extension) => this.props.allExtensionStatus[extension].id
    );

    Promise.all(
      allUniqueExtensionsId.map((id) => enableDisableExtension(id, "DISABLED"))
    )
      .then((response) => {
        this.props.updateAllExtensionStatus(() => {
          this.setState({ submitting: false });
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submitting: false });
      });
  };

  render() {
    const filteredArray = this.props.details.filter(
      (detail) => Object.values(detail)[0].extensionRequired !== false
    );
    if (!filteredArray.length && this.props.isLoggedIn) return null;
    return (
      <div className="feature-div" id={this.props.id}>
        <div className="feature-name-install-btn-wrp">
          <img className="illustration-image" alt="" src={this.props.icon} />
          <p className="feature-title">
            <Message dictKey={this.props.feature} />
          </p>
          {this.props.isLoggedIn && (
            <button
              onClick={
                this.state.enableUninstallBtn
                  ? this.uninstallSelectedFeatures
                  : this.installSelectedFeatures
              }
              className={
                this.state.enableUninstallBtn
                  ? "feature-uninstall-btn"
                  : "feature-install-btn"
              }
              type="button"
              disabled={this.state.submitting}
            >
              {this.state.enableUninstallBtn ? (
                <>
                  <Message
                    dictKey={
                      this.state.submitting ? "uninstalling" : "uninstall"
                    }
                  />
                  {this.state.submitting && "..."}
                </>
              ) : (
                <>
                  <Message
                    dictKey={this.state.submitting ? "installing" : "install"}
                  />
                  {this.state.submitting && "..."}
                </>
              )}
            </button>
          )}
        </div>
        <div className="feature-subcategories">
          {this.getFeatureSubCategories()}
        </div>
      </div>
    );
  }
}

const ImageArray = [
  OnlineStoreIcon,
  CatalogueIcon,
  PaymentsIcon,
  MarketingIcon,
  OrderManageIcon,
  DeliveryIcon,
  CustomerManagement,
  StaffManagement,
  ChannelsIcon,
  AnalyticsIcon,
  AppsIcon,
  DataSecurityIcon,
];
class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNav: "hostingandsecurity",
      isLoggedIn: false,
      loading: false,
    };
    this.getFads = this.getFads.bind(this);
    this.updateActiveClassOnNavigation =
      this.updateActiveClassOnNavigation.bind(this);
  }

  getFads(isDirectionRTL) {
    let fads = FeaturesAndDetails.map((feature, index) => {
      return (
        <FeatureCategories
          key={"fad-" + index}
          feature={Object.keys(feature)}
          details={FeaturesAndDetails[index][Object.keys(feature)]}
          isLoggedIn={this.state.isLoggedIn}
          id={Object.keys(feature)
            .toString()
            .replace(/[\W_]/g, "")
            .toLowerCase()}
          allExtensionStatus={this.state.allExtensionStatus}
          updateAllExtensionStatus={this.updateAllExtensionStatus}
          icon={ImageArray[index]}
          isDirectionRTL={isDirectionRTL}
        />
      );
    });
    return fads;
  }

  updateActiveClassOnNavigation(e) {
    let detailsDiv = window;
    let scrollPos = parseInt(detailsDiv.pageYOffset) + 400;
    let featureDivs = document.querySelectorAll(".feature-div");
    let _this = this;
    let currentNav;
    for (let i = 0; i < featureDivs.length; i++) {
      if (featureDivs[i].offsetTop <= scrollPos) {
        currentNav = document
          .querySelectorAll(".features-side-bar ul li")
          [i].querySelector("a")
          .getAttribute("id")
          .substr(1);
      }
    }
    currentNav = currentNav === undefined ? "hostingandsecurity" : currentNav;
    if (currentNav !== this.state.currentNav) {
      _this.setState({
        currentNav: currentNav,
      });
    }
  }

  getFeaturesList(isDirectionRTL) {
    let featuresList = FeaturesAndDetails.map((feature, index) => {
      return (
        <FeaturesList
          key={"fl-" + index}
          feature={Object.keys(feature)}
          id={Object.keys(feature)
            .toString()
            .replace(/[\W_]/g, "")
            .toLowerCase()}
          currentNav={this.state.currentNav}
          isDirectionRTL={isDirectionRTL}
        />
      );
    });

    return featuresList;
  }

  getBannerFeatures = (isDirectionRTL) => {
    return bannerFeaturesList.map((feature) => (
      <div className={`banner-feature-desc ${isDirectionRTL && "rtl-support"}`}>
        <div className="banner-img-wrp">
          <img className="banner-feature-img" src={feature.image} alt="" />
        </div>
        <Message dictKey={feature.desc} />
      </div>
    ));
  };
  componentDidMount() {
    let headers = document.querySelectorAll("header > div");
    headers.forEach((heading) => {
      heading.classList.add("relative");
    });
    let commonHeader = document.querySelector("header.common-header");
    let commonHeaderHeight = commonHeader && commonHeader.offsetHeight;
    let subHeader = document.querySelector(".eazy-header > div");
    let featuresNav = document.querySelector(".features-side-bar");
    let footer = document.querySelector("footer");
    let brief = document.querySelector(".brief");
    let featureDeatail = document.querySelector(".feature-details");
    // const paginatedValue = false
    // getExtensionData(paginatedValue)
    //   .then((response) => {
    //     const allExtensionStatus = {}
    //     response.data.extension.forEach(({ slug, status, id }) => {
    //       allExtensionStatus[slug] = { status, id }
    //     })
    //     this.setState({
    //       allExtensionStatus,
    //       loading: false,
    //     });
    //   })
    //   .catch((err) => {
    //     this.setState({ loading: false });
    //     if(err.code === 401){
    //       clearSession()
    //       window.location.reload()
    //     }
    //     console.error(err)
    //   });
    window.onscroll = (e) => {
      const isDirectionRTL = this?.props?.direction === "rtl";
      this.updateActiveClassOnNavigation();
      let subHeaderRect = subHeader && subHeader.getBoundingClientRect();
      let footerRect = footer && footer.getBoundingClientRect();
      let briefRect = brief && brief.getBoundingClientRect();
      if (window.pageYOffset >= commonHeaderHeight) {
        subHeader && subHeader.classList.add("fixed");
        this.setState({ hiddenHeader: true });
      } else {
        subHeader && subHeader.classList.remove("fixed");
        this.setState({ hiddenHeader: false });
      }
      if (
        ((briefRect && briefRect.bottom + 150) <=
          (subHeaderRect && subHeaderRect.bottom) &&
          (footerRect && footerRect.top - 70) > window.innerHeight) ||
        (footerRect && footerRect.top) <= window.innerHeight
      ) {
        featuresNav && featuresNav.classList.add("fixed-fd");
        if (isDirectionRTL) {
          featureDeatail &&
            featureDeatail.classList.add("adjust-right-margin-rtl-support");
        } else {
          featureDeatail && featureDeatail.classList.add("adjust-left-margin");
        }
      } else {
        featuresNav && featuresNav.classList.remove("fixed-fd");
        if (isDirectionRTL) {
          featureDeatail &&
            featureDeatail.classList.remove("adjust-right-margin-rtl-support");
        } else {
          featureDeatail &&
            featureDeatail.classList.remove("adjust-left-margin");
        }
      }
      if (footerRect && footerRect.top - 70 <= window.innerHeight) {
        featuresNav && featuresNav.classList.add("fixed-fd");
        featuresNav && featuresNav.classList.add("flex-end");
        if (isDirectionRTL) {
          featureDeatail &&
            featureDeatail.classList.add("adjust-right-margin-rtl-support");
        } else {
          featureDeatail && featureDeatail.classList.add("adjust-left-margin");
        }
      } else {
        featuresNav && featuresNav.classList.remove("flex-end");
      }
      // As of now extension installation/uninstallation is not required from website so disabling this feature
      // if(window.localStorage.getItem('token')){
      //   this.setState({isLoggedIn: true})
      // }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let featureListDiv = document.querySelector(".features-side-bar");
    let listContainer =
      featureListDiv && featureListDiv.getBoundingClientRect();
    let activeFeatureDoc = document.querySelector(".feature-active");
    let activeFeature =
      activeFeatureDoc && activeFeatureDoc.getBoundingClientRect();

    if (
      (listContainer && listContainer.bottom) <=
      (activeFeature && activeFeature.bottom)
    ) {
      animateScrollTo(document.querySelector(".feature-active"), {
        element: featureListDiv,
      });
    } else if (
      (listContainer && listContainer.top) >=
      (activeFeature && activeFeature.top)
    ) {
      animateScrollTo(document.querySelector(".feature-active"), {
        element: featureListDiv,
      });
    }
    // if (prevState.isLoggedIn !== this.state.isLoggedIn) {
    //   const paginatedValue = false
    //   getExtensionData(paginatedValue)
    //     .then((response) => {
    //       const allExtensionStatus = {}
    //       response.data.extension.forEach(({ slug, status, id }) => {
    //         allExtensionStatus[slug] = { status, id }
    //       })
    //       this.setState({
    //         allExtensionStatus,
    //         loading: false,
    //       });
    //     })
    //     .catch((err) => {
    //       this.setState({ loading: false });
    //       console.error(err)
    //     });
    // }
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  /*
    get updated extension statuses after installing or uninstalling a feature
  */
  updateAllExtensionStatus = (callBack) => {
    const paginatedValue = false;
    getExtensionData(paginatedValue)
      .then((response) => {
        const allExtensionStatus = {};
        response.data.extension.forEach(({ slug, status, id }) => {
          allExtensionStatus[slug] = { status, id };
        });
        this.setState({ allExtensionStatus }, callBack);
      })
      .catch((error) =>
        console.error("Error updating extension status: ", error)
      );
  };

  render() {
    const { language, updateLanguage, direction } = this.props;
    let canonicalUrl = `https://staging.zopping.com/features`;
    if (typeof window !== "undefined") {
      if (window.location.host === "zopping.com") {
        canonicalUrl = `https://zopping.com/features`;
      }
    }
    const isDirectionRTL = direction === "rtl";
    return (
      <LayoutWrapper
        language={language}
        showFooterForm
        location={this.props.location}
      >
        <div className="eazy-page">
          <Helmet title="Zopping - Features">
            <meta
              name="description"
              content="Explore our feature-rich online store builder. Over 50+ advanced features and extensions to customize your online store as per your needs.
"
            />
            <link rel="canonical" href={canonicalUrl} />
          </Helmet>
          <EazyHeader
            language={language}
            updateLanguage={updateLanguage}
            current="features"
            hiddenHeader={this.state.hiddenHeader}
            isDirectionRTL={isDirectionRTL}
          />
          <div className="eazy-features">
            <section className="overview tc">
              <section className="brief">
                <div className="heading-description">
                  <h1 className="features-heading">
                    <Message dictKey="zopping.features" />
                  </h1>
                  <p className="features-subheading">
                    <Message dictKey="feature.description" />
                  </p>
                </div>
                <div className="banner-feature-wrp">
                  {this.getBannerFeatures(isDirectionRTL)}
                </div>
                <div className="mobile-slideshow">
                  <SlideShow isDirectionRTL={isDirectionRTL}>
                    {bannerFeaturesList.map((feature) => (
                      <div className="banner-feature-wrp-mobile">
                        <div
                          className={`banner-feature-desc ${
                            isDirectionRTL ? "rtl-support" : ""
                          }`}
                        >
                          <div className="banner-img-wrp">
                            <img
                              className="banner-feature-img"
                              src={feature.image}
                              alt=""
                            />
                          </div>
                          <Message dictKey={feature.desc} />
                        </div>
                      </div>
                    ))}
                  </SlideShow>
                </div>
              </section>
            </section>
            <div
              className={`features-details ${
                isDirectionRTL ? "rtl-support" : ""
              }`}
            >
              <div className="nav-bar-wrapper">
                <div
                  className={`features-side-bar ${
                    isDirectionRTL ? "rtl-support" : ""
                  }`}
                >
                  <ul>{this.getFeaturesList(isDirectionRTL)}</ul>
                </div>
              </div>
              <div
                className="feature-details"
                onScroll={this.updateActiveClassOnNavigation}
              >
                {this.getFads(isDirectionRTL)}
              </div>
            </div>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}

export default WithProviders(Features);
